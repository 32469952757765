        .footer {
            display: none !important;
        }

        .section-left-side {
            background-repeat: no-repeat;
            background-position: 0px 0px;
            background-size: cover;
        }

        .section-right-side1 {
           width: 96%;
            height: 200vh;
            overflow-y: auto;
            scroll-snap-type: y mandatory;
        }

        .background-header-objects {
            position: fixed;
            top: 0;
            height: 100vh;
            right: 0;
            width: 4%;
            background: #FBA91B;
            box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25) inset;
            z-index: 1;
        }
        .right-header3 {
            display: none;
        }

        .right-text3 {
            color: rgba(255, 255, 255, 0.58);
            position: relative;
            font-size: 28px;
            top: 14%;
            margin-left: 75px;
            margin-right: 100px;
            line-height: 220%;
        }

        .right-header-object {
            color: #8c8c8c;
            position: absolute;
            font-size: 34px;
            top: 101vh;
            margin-left: 3.3%;
            margin-right: 100px;
            font-weight: bold;
            text-align: left;
            cursor: default;
            scroll-snap-type: start;
            scroll-behavior: smooth;
        }

        .cards1 {
            display: none;
        }

        .card-text1 {
            display: block;
            padding-left: 20px;
            padding-top: 10px;
            color: white;
            font-size: 17px;
        }


        .div-objects1 {
            width: 90%;
            height: 30vh;
            margin: 2% 5% 5% 3%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            position: absolute;

        }

        .div-objects-name {
            background-color: #5b5b5b;
            width: 25%;
            height: 80%;
            border-radius: 5px;
            margin: 5px;
            transition: 0.2s;
            margin-top: 5.5%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            text-align: center;
            text-decoration: none;

        }

        .div-objects-name img {
            text-align: center;
            text-decoration: none;
            filter: grayscale(100%);

        }


        .div-objects-name:hover {
            transform: scale(1.1);
            z-index: 3;
            box-shadow: 0px 4px 14px 8px rgba(0, 0, 0, 0.25);
            background-color: rgba(39, 39, 39, 0.56);

        }


        .name-p-div p {
            height: auto;
            width: auto;
            text-decoration: none;
            margin: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-right: -50%;
            transform: translate(-50%, -50%);

        }

        .name-p-div {
            height: 100%;
            width: 100%;
            font-size: 29px;
            font-weight: 900;
            color: rgba(255, 255, 255, 0);
            margin: 0;
        }

        .name-p-div:hover {
            color: rgb(255, 255, 255);
            box-shadow: inset 0px 0px 250px 10000px rgba(0, 0, 0, 0.1);
        }



        .name-objects1 {
            font-size: 26px;
            color: #e0e0e0;
            position: absolute;
            top: 0;
            width: 100%;
            margin-left: 1%;
            cursor: default;

        }

        .objects-card {
            width: 90%;
            height: auto;
            display: flex;
        }

        .div-objects2 {
            width: 90%;
            height: 30vh;
            margin: 22% 5% 5% 3%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            position: absolute;

        }

        .div-objects-name2 {
            background-color: #5b5b5b;
            width: 25%;
            height: 80%;
            border-radius: 5px;
            margin: 5px;
            transition: 0.2s;
            margin-top: 5.5%;

        }

        .div-objects-name2:hover {
            transform: scale(1.1);
            z-index: 2;
            box-shadow: 0px 0px 40px 10px rgba(0, 0, 0, 0.39);
        }


        .div-objects-name2 p {
            font-size: 19px;
            font-weight: bolder;
            text-align: center;

        }


        .name-objects2 {
            font-size: 26px;
            color: #e0e0e0;
            position: absolute;
            top: 0;
            width: 100%;
            margin-left: 1%;
            cursor: default;

        }

        .container {
            position: absolute;
            top: 0;
            width: 100%;
            height: 85vh;
        }


        .main-img {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background-image: url('/src/assets/img/Objects/Park.jpg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }

        .p_name {
            position: absolute;
            left: 5%;
            right: 70%;
            top: 20%;
            background: linear-gradient(100.79deg, #E16C00 11%, #FBA91B 57.7%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            font-size: 7vh;
            font-weight: bolder;

        }

        .realization {
            position: absolute;
            bottom: 4%;
            //background: linear-gradient(100.55deg, #05FF00 -11.6%, #04B800 92.15%);
            background: linear-gradient(100.55deg, #ffffff -11.6%, #bebebe 92.15%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 3.1vh;
            font-weight: bolder;
            text-align: right;
            width: 41.21%;
        }

        .name-div,
        .name-div2,
        .name-div3 {
            position: absolute;
            top: 20%;
            padding-left: 100px;
            font-size: 6.5vh;
            font-weight: bolder;
            line-height: 7vh;

        }

        .name-div {
            background: linear-gradient(100.79deg, #E16C00 11%, #FBA91B 57.7%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.10);

        }

        .name-div2 {
            background: linear-gradient(100.79deg, #2B95E2 11%, #0FE5F2 57.7%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.10);
        }

        .name-div3 {
            background: conic-gradient(from 125.73deg at 3.41% 0%, #FFFFFF -78.2deg, #70A9FF 52.53deg, #FFFFFF 281.8deg, #70A9FF 412.53deg);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            mix-blend-mode: normal;
            text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.10);

        }

        .p_name6 {
            position: absolute;
            left: 340%;
            bottom: -132%;
            background: radial-gradient(24.03% 127.27% at 75.97% 50%, #ACACAC 0%, #C8C8C8 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            font-size: 3.3vh;
            font-weight: bolder;
            white-space: nowrap;
        }

        .about-button2 {
            position: absolute;
            bottom: 9%;
            margin-left: 100px;
            height: auto;
            width: 22vh;
            font-size: 3.1vh;
            text-align: center;
            padding: 2vh;
            border-radius: 5px;
            background: linear-gradient(93.11deg, #F29A00 6.79%, #EBBB66 83.54%);
            //box-shadow: 0 0 40px 10px rgba(0, 0, 0, 0.39);
            transition: 0.2s;
            z-index: 2;
            text-decoration: none;
            color: black;
            text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.10);
        }


        .about-button2:hover {
            transform: scale(1.1);
            z-index: 3;
            background: linear-gradient(93.11deg, #F29A00 15.79%, #EBBB66 100.54%);
            cursor: pointer;
            text-decoration: none;
        }

        .container2 {
            width: 100%;
            height: 80vh;
            position: absolute;
            top: 0;
        }

        .div-photo {
            height: 85vh;
            width: 100%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }

        .yours-custom-class2 {
            height: 100%;
            width: 100%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            text-align: center;

        }

        .paging-item {
            background-color: #71717100 !important;
        }

        .arrow-down {
            position: absolute;
            top: 85vh;
            text-align: center;
            height: 15vh;
            width: 96%;
            background-image: url('../../../assets/svg/arrow_down.svg');
            background-position: center;
            background-repeat: no-repeat;
            opacity: 0.5;
            scroll-behavior: smooth;

        }






