@media screen and (max-width: 833px) {

    @keyframes fade-in {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    @keyframes fade-out {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
        }
    }

    @keyframes mymove {
        0% {
            top: 100%;

        }
        100% {
            bottom: 0;
        }
    }

    @keyframes mymove2 {
        to {
            margin-bottom: 100%;
        //height: 0%;
        }

        from {
            margin-top: 100%;
            height: 0;
        }
    }

    @keyframes mymove3 {
        0% {
            right: 100%;

        }
        100% {
            left: 0;
        }
    }

    /*  для FULL HD */
    .right-text3 {
        display: none;
    }
    .right-header-object {
        display: none;
    }
    .section-left-side-fullhd {
        display: none;
    }


    .header {
        display: none !important;
    }

    /*  КАРУСЕЛЬ */
        .name-carousel {
            display: block;
            color: white;
            font-size: 18px;
            margin-left: 17px;
            margin-right: 17px;
            padding-right: 100px;
            margin-top: 30px;

        }
        .cards1 {
            display: block;
            margin-top: 5%;
            width: 100%;
            border-radius: 20px;
        }
        .card-div1 {
            overflow: hidden;
            margin-inline: 15px;
            width: 80%;
            height: auto;
            border-radius: 5px;
        }
        .card-div-perekrestok {
            overflow: hidden;
            margin-inline: 15px;
            width: 80%;
            height: 100%;
            border-radius: 20px;
        }
        .card-div-header {
            width: 100%;
            height: 60px;
            text-align: center;
        }
        .card-div-header img {
            width: 55%;
            height: 100%;

        }
        .card-text1 {
            display: block;
            text-align: left;
            padding: 20px;
            color: #2c2c2c;
            font-size: 19px;
            font-weight: bold;
            margin-top: 0;
            margin-bottom: 0;

        }
        .card-img1 {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            width: 100%;
            height: 250px;
            position: relative;
            bottom: 0;
        }

    .div-objects1,
    .div-objects2,
    .right-header-object,
    .about-button2,
    .container,
    .slider-container
    {
        display: none !important;
    }






    /*  Нижний Хэдер */
        .footer {
        display: block !important;
        position: fixed;
        bottom: 0;
        z-index: 100;
        width: 100%;
    }

    .section-right-side1 {
        width: 100%;
        height: 120vh;
        margin-top: 13%;
    }

}


