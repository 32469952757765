@media screen and (max-width: 833px) {

    /*  для FULL HD */
    .right-text3,
    .right-header,
    .section-left-side-fullhd {
        display: none ;
    }


    .section-left-side-mob{
        display: block;
        background-color: #fba91b;
        width: 100%;
        height: 7vh;
        position: relative;
    }

    .section-left-side {
        background-color: #FBA91B;
    }



    .left-header-fullhd {
        display: block;
        font-size: 22px;
        color: #fba91b;
        position: relative;
        top: 10px;
        margin-left: 5%;
        margin-right: 5%;

    }

    .right-header-company {
        font-size: 22px;
        color: #fba91b;
        position: relative;
        top: 80px;
        margin-left: 5%;
        margin-right: 5%;
    }

    .left-text {
        color: rgba(255, 255, 255, 0.58);
        position: relative;
        font-size: 19px;
        top: 10px;
        margin-left: 5%;
        margin-right: 5%;
    }

    .right-text {
        color: rgba(255, 255, 255, 0.58);
        position: relative;
        font-size: 19px;
        top: 80px;
        margin-left: 5%;
        margin-right: 5%;
    }

    .author {
        color: rgba(255, 255, 255, 0.58);
        position: relative;
        font-size: 19px;
        top: 4vh;
        margin-right: 10%;
    }

    .head {
        height: 10vh;
    }

}