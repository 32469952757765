
.section-company{
    position: fixed;
    top: 5%;
    left: 5%;
    z-index: 2;
}


.section-company2 {
    position: fixed;
    top: -5%;
    left: -4%;
    display: flex;
    height: 105%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    filter: blur(8px);

}

.header-company{
    position: fixed;
    top: -4vh;
    right: 0;
    height: 104vh;
    width: 4%;
    background: linear-gradient(180deg, #FBA91B 0%, #F9A91F 100%);
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.20) inset;
    z-index: 4;
}

.right-header-company{
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin: 0;
    padding: 0;
}

.right-header-company2{
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 4%;
    background-color: #FBA91B;
}

.left-card{
    display: flex;
    width: 43%;
    height: 100%;
    border-radius: 5px;
    background: rgba(60, 65, 86, 0.63);
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25) inset;
    margin: 0 2% 1% 0;


}

.name-left {
    font-size: 1.27vw;
    color: rgba(255, 255, 255, 0.89);
    margin-left: 10%;
    margin-right: 10%;
}

.left-text {
    display: flex;
    color: rgba(255, 255, 255, 0.89);
    font-size: 28px;
    margin-left: 10%;
    margin-right: 10%;
}

.right-card{
    position: absolute;
    right: 2%;

    display: flex;
    width: 43%;
    height: 100%;
    border-radius: 5px;
    background: rgba(60, 65, 86, 0.63);
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25) inset;
    margin: 0 7% 1% 5%;
}

.name-right {
    font-size: 1.27vw;
    color: rgba(255, 255, 255, 0.89);
    margin-left: 10%;
    margin-right: 10%;
}

.right-text {
    display: flex;
    color: rgba(255, 255, 255, 0.89);
    font-size: 28px;
    margin-left: 10%;
    margin-right: 10%;
}



