          @font-face {
            font-family: 'CirceBold';
            src: url('./assets/fonts/Roboto-Bold.ttf');
          }

          @font-face {
            font-family: 'CirceRegular';
            src: url('./assets/fonts/Roboto-Regular.ttf');
          }

          @font-face {
            font-family: 'CirceLight';
            src: url('./assets/fonts/Roboto-Light.ttf');
          }

          :root {
            --bg-color: #25262A;
            //--primary-color: #FBA91B;
              scroll-behavior: smooth;
          }

          body {
            background-color: #25262A;
            font-family: 'CirceRegular';
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;


          }

          #root {
           height: fit-content;
              tab-index: calc (1vw + 1vh);
          }

          @keyframes fade-in {
            from {
              opacity: 0;
            }

            to {
              opacity: 1;
            }
          }

          @keyframes fade-out {
            from {
              opacity: 1;
            }

            to {
              opacity: 0;
            }
          }

          .allImg {
            display: block;
            width: 100%;
            height: 100%;
            background: #FBA91B;
          }

          .quote {
              font-size: 2.5vh;
              text-align: left;
              margin-top: 50%;
              margin-left: 10%;
              margin-right: 10%;
              line-height: 45px;
              font-family: "Rubik Mono One";
              font-style: italic;
              animation: fade-in 10s;
              color: rgba(0, 0, 0, 0.78);
              mix-blend-mode: multiply;
              cursor: default;
          }
          .quoteAutor {
              font-size: 2.5vh;
              text-align: right;
              margin-top: 0%;
              margin-right: 10%;
              margin-left: 10%;
              font-family: "Rubik Mono One";
              font-style: italic;
              font-weight: lighter;
              animation: fade-in 10s;
              color: rgba(0, 0, 0, 0.78);
              mix-blend-mode: multiply;
              cursor: default;
          }

          .right-text-all {
              padding: 20px 75px 20px 75px;
              margin: 10px 2% 10px 2%;
              line-height: 3vh;
              border-radius: 5px;
              background: rgba(60, 65, 86, 0.63);
              box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25) inset;
              display: flex;
              flex-wrap: wrap;
              animation: text2 1s 1;

          }


          @keyframes text2 {
              0%{
                  right: 0;
                  width: 88%;
                  height: auto;
                  opacity: 0;
              }
              20%{
                  margin-left: 100%;
                  height: auto;
                  opacity: 0;
              }
              100%{
                  left: 0;
                  width: 88%;
                  height: auto;
                  opacity: 100%;
              }
          }


          .text-name {
              padding: 20px 75px 20px 75px;
              margin: 10px 2% 10px 2%;
              line-height: 3vh;
              border-radius: 5px;
              background: rgba(60, 65, 86, 0.63);
              box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
              display: flex;
              flex-wrap: wrap;
          }

          .right-text5 {
              text-decoration: none;
              width: max-content;
              height: fit-content;
              font-size: 1.27vw;
              color: #8c8c8c;
              line-height: auto;
              transition: 0.5s;
              margin: 0.6vw;
          }

          a.right-text5 {
              border-radius: 5px;
              background: rgba(60, 65, 86, 0.63);
              box-shadow: 2px 4px 4px 2px rgba(0, 0, 0, 0.15);
              padding: 1%;

          }

          .right-text5:hover {
              color: #ffffff;
              scale: 102%;
          }


          .section-left-side {
              width: 50%;
          }



          .hystmodal-menu {
              display: none;
          }

          .section-left-side-mob {
              display: none;
          }

          .name-carousel {
              display: none;
          }

          .button-photo {
              display: none;
          }

          .hystmodal {
              display: none;
          }

          .space-text {
              display: none;
          }

          .name{
              display: none;
          }

          .home-logo{
              display: flex;
              position: fixed;
              height: 40px;
              width: 40px;
              justify-content: center;
              top:2vw;
          }


          .company-logo{
              display: flex;
              position: fixed;
              height: 40px;
              width: 40px;
              top: 11vh;
              justify-content: center;
          }

          .uslugi-logo{
              display: flex;
              position: fixed;
              top: 20vh;
              justify-content: center;
          }

          .projects-logo{
              display: flex;
              position: fixed;
              top: 30vh;
              justify-content: center;
          }

          .contacts-logo{
              display: flex;
              position: fixed;
              top: 40vh;
              justify-content: center;
          }

          .home-logo:after,
          .company-logo:after,
          .uslugi-logo:after,
          .projects-logo:after,
          .contacts-logo:after {
              position: absolute;
              right: 100%;
              bottom: 0%;
              min-width:140px;
              margin-right: 30px;
              content: attr(data-info);
              visibility: hidden;
              font-size: 20px;
              color: #fff;
              padding: 13px;
              text-align: center;
              border-radius: 5px;
              background: rgba(60, 65, 86, 0.63);
          }

          .home-logo:hover:after,
          .company-logo:hover:after,
          .uslugi-logo:hover:after,
          .projects-logo:hover:after,
          .contacts-logo:hover:after {
              visibility: visible;
          }

          .top-header {
              font-size: 3vw;
              margin-left: 7vw;
              animation: text2 1s 1;
          }

          .text-left {
              font-size: 1.2vw;
              background-color: none;
              width: 46%;
              height: auto;
              color: #D9D2C4;
              animation: text4 3s 1;;
          }

          .text-right {
              display: flex;
              width: 86%;
              height: auto;
              font-size: 1.2vw;
              margin-left: 50%;
              margin-right: 6%;
              color: #D9D2C4;
          }

          .section-title {
              font-size: 1.2vw;
              color: #ffffff;
              height: auto;
              margin-left: 7vw;
          }

          .div-objects-card3 {
              width: 90%;
              height: 30vh;
              margin: 2% 5% 5% 7vw;
              display: flex;
              flex-direction: row;
              justify-content: space-around;
              position: absolute;
              border-radius: 5px;
              background: rgba(60, 65, 86, 0.63);
              box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
              align-items: center;
          }

          .name-popular {
              font-size: 1.7vw;
              color: #e0e0e0;
              margin: 0 7vw 0% 7vw;
          }

          .div-objects-popular {
              background-color: #5b5b5b;
              width: 25%;
              height: 80%;
              border-radius: 5px;
              margin: 5px;
              transition: 0.2s;
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
              text-align: center;
              text-decoration: none;
              mix-blend-mode: luminosity;

          }

          .div-objects-popular:hover {
              transform: scale(1.1);
              z-index: 3;
              box-shadow: 0px 4px 14px 8px rgba(0, 0, 0, 0.25);
              background-color: rgba(39, 39, 39, 0.56);
              mix-blend-mode: normal;
          }

          .empty {
              height: 23vw;
          }



