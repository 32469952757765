.footer {
    display: none !important;
}

.section-left-side {
    background-repeat: no-repeat;
    background-position: 0px 0px;
    background-size: cover;
}

.section-right-side {
    background-color: #25262A;
}

.right-header3 {
    display: none;
}

.right-text3 {
    color: rgba(255, 255, 255, 0.58);
    position: relative;
    font-size: 28px;
    top: 14%;
    margin-left: 100px;
    margin-right: 100px;
    line-height: 220%;
}


.cards-hot {
    display: none;
}

.card-text77 {
    display: block;
    padding-left: 20px;
    padding-top: 10px;
    color: white;
    font-size: 17px;
}

.section-left-side-fullhd {
    display: block;
    width: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.about-wind-button{
    text-align: center;
    width: 90px;
    height: 20px;
    border: 10px solid #fba91b;
    background-color: #fba91b;
    border-radius: 5px;
    color: rgb(44, 44, 44);
}
