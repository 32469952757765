header {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    width: 4vw;
    height: 100%;
    padding: 0 0 30px 0;
    position: fixed;
    right: 0;
    user-select: none;
    box-sizing: border-box;
    z-index: 3;
}

header .header__left {
    display: flex;
    position: fixed;
    bottom: 2%;
    z-index: 2;
}

header .header__left__logo {
    width: auto;
    height: 57px;
    cursor: pointer;
    rotate: -100grad;
}

.menu-link {
    text-align: right;
    margin: 5px 0;
}

.menu-link-text>a,
.menu-address>a,
.menu-phone>a,
.menu-mail>a {
    text-decoration: none;
    color: #A2A2A2;
}

.menu-link-text {
    font-size: 28px;
    line-height: 45px;
    color: #BEBEBE;
    text-decoration: none;
    letter-spacing: 0.025em;
    mix-blend-mode: overlay;
}

.menu-link-text:hover {
    color: rgb(255, 201, 113);
    transition: all .2s;
}

.menu-link-text.selected-menu-link {
    color: #FBA91B;
}

.menu-link-text2:hover {
    color: rgb(255, 201, 113);
}


.company {
    margin-top: 180px;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    text-align: right;
    color: #A2A2A2;
    text-decoration: none;
}

.menu-address {
    margin-top: auto;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    text-align: right;
    color: #A2A2A2;
}
.menu-address1 {
    margin-top: 0px;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    text-align: right;
    text-decoration: none;
}

.menu-link-text1 {
    font-size: 24px;
    line-height: 30px;
    color: #A2A2A2;
    text-decoration: none;
    //mix-blend-mode: overlay;
}

.menu-phone {
    font-weight: 400;
    font-size: 24px;
    line-height: 80px;
    text-align: right;
    color: #706F6F;
    margin-top: 0;
    margin-bottom: 0px;
}

.menu-mail {
    margin-top: 0;
    font-weight: 400;
    font-size: 24px;
    line-height: 59px;
    text-align: right;
    color: #5E5C5C;
}

.burger-menu {
    box-sizing: border-box;
    height: 30px;
    width: 35px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    cursor: pointer;
}

.burger-bar {
    width: 35px;
    height: 3px;
    border-radius: 1px;
}

.menu {
    display: flex;
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    padding: 20px 60px 20px 60px ;
}

.menu-container {
    width: 100%;
    height: 95vh;
    display: flex;
    box-sizing: border-box;
    background: linear-gradient(0deg, rgba(104, 104, 104, 0.44), rgba(104, 104, 104, 0.44)), linear-gradient(126.59deg, rgba(255, 255, 255, 0.4) 12.11%, rgba(255, 255, 255, 0.1) 73.08%);
    box-shadow:  0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 24px #000000;
    backdrop-filter: blur(101.5px);
    border-radius: 10px;
    position: relative;
}

.menu-right-side,
.menu-left-side {
    display: flex;
    width: 50vw;
    height: 100%;
    box-sizing: border-box;
}

.menu-left-side {
    justify-content: center;
    align-items: center;
}

.menu-right-side {
    flex-direction: column;
    padding-top: 120px;
    padding-right: 46px;
}

/* ------------- sliding menu ------------------ */
.hidden {
    display: none;
}

.visible {
    display: inherit;
}

/* ----- animations--------- */
/* clicked */
.burger-bar.clicked:nth-child(1),
.burger-bar.clicked:nth-child(3) {
    background-color: #D60000;
    width: 40px;
    transition: ease-out .3s;
}

.burger-bar.clicked:nth-child(1) {
    transform: rotate(45deg);
    transform-origin: left top;
}

.burger-bar.clicked:nth-child(2) {
    transform: scale(0);
    transition: ease-out .3s;
}

.burger-bar.clicked:nth-child(3) {
    transform: rotate(-45deg);
    transform-origin: left bottom;
}

/* unclicked */
.burger-bar.unclicked {
    transform: rotate(0) translate(0);
    transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.3s;
}
.home-logo,
.company-logo,
.uslugi-logo,
.projects-logo,
.contacts-logo {
    z-index: 2;
    height: 2vw;
    width: 2vw;
}

.language {
    position: fixed;
    bottom: 0%;
    left: 96%;
    z-index: 2;
    background: rgba(0, 0, 0, 0.30);
    width: 4%;
    height: 2%;

}

.text-lang {
    text-align: center;
    justify-content: center;
    color: white;
    margin: 0;
}