@import "react-alice-carousel/lib/alice-carousel.css";


        .footer {
            display: none !important;
        }

        .section-left-side {
            background-repeat: no-repeat;
            background-position: 0px 0px;
            background-size: cover;
        }

        .section-left-side-fullhd2 {
            width: 55%;
            height: calc(100vh - 13%);
            margin: 8% 5% 5% 5%;

            background-color: none;
        }

        .section-right-side2 {
            width: 50%;
            height: 100vh;
        }

        .rout {
            font-size: 2vh;
            color: #8c8c8c;
            cursor: default;
        }

        .rout_link {
            font-size: 2vh;
            color: #8c8c8c;
            text-decoration: none;
        }


        .yours-custom-class {
            height: 10vh;
            border-radius: 10px;
            text-align: center;
        }

        .div-photo2 {
            height: 50vh;
            width: 100%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }

        img.yours-custom-class {
            text-align: center;
            width: 100%;
            height: 40%;
            border-radius: 10px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;

        }

        li.alice-carousel__stage-item {
            width: 0;
        }

        .alice-carousel__prev-btn{
            height: 50vh;
            position: absolute;
            top: 0;
            left: -2%;
            width: 10%;
        }

        .alice-carousel__next-btn {
            height: 50vh;
            position: absolute;
            top: 0;
            right: -2%;
            width: 10%;
        }


        .alice-carousel__next-btn-wrapper,
        .alice-carousel__prev-btn-wrapper {
            height: 100%;
            width: 100%;
        }


        .alice-carousel__next-btn-item,
        .alice-carousel__prev-btn-item {
            font-size: 60px;
            color: #FBA91B;

        }

        p.alice-carousel__next-btn-item,
        p.alice-carousel__prev-btn-item {
            text-align: center;
            margin-top: 21vh;
        }

        .alice-carousel__dots-item:not(.__custom).__active {
        background-color: #fba91b;
        }

        .stroke-1 {
            margin: 21% 10% 5% 0;
            font-size: 29px;
            color: #d7d7d7;
            cursor: default;
        }


        .stroke-2 {
            margin-bottom: 5%;
            margin-top: 0;
            font-size: 19px;
            color: #b4b4b4;
            line-height: 40px;
            cursor: default;

        }
        .stroke-3 {
            margin: 0% 35% 3% 0;
            font-size: 1.8vh;
            color: #b4b4b4;
            line-height: 2.5vh;
            cursor: default;

        }

        .stroke-4 {
            margin: 6.5% 0 3% 0;
            font-size: 2vh;
            color: #b4b4b4;
            line-height: 30px;
            cursor: default;

        }

        .text_about {
            margin-right: 15%;
            color: #b4b4b4;
            position: absolute;
            right: 0;
            cursor: default;
        }

        .slider-control-bottomcenter {
            position: relative;
            top: 38px;
        }



