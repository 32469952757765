          @media screen and (max-width: 833px) {


          @font-face {
            font-family: 'CirceBold';
            src: url('./assets/fonts/Roboto-Bold.ttf');
          }

          @font-face {
            font-family: 'CirceRegular';
            src: url('./assets/fonts/Roboto-Regular.ttf');
          }

          @font-face {
            font-family: 'CirceLight';
            src: url('./assets/fonts/Roboto-Light.ttf');
          }

          :root {
            --bg-color: #25262A;
              //height: auto;
            //--primary-color: #FBA91B;

          }




          body {
            margin: 0;
            background-color: #25262A;
            font-family: 'CirceRegular';
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
          }

          #root {
            //min-height: 120vh;
            position: static;
            overflow: hidden;

          }

          @keyframes fade-in {
            from {
              opacity: 0;
            }

            to {
              opacity: 1;
            }
          }

          @keyframes fade-out {
            from {
              opacity: 1;
            }

            to {
              opacity: 0;
            }
          }

          .allImg {
            display: block;
            width: 100%;
            height: 100%;
          }

              /*  Нижний Хэдер */
              .footer {
                  display: block !important;
                  position: fixed;
                  bottom: 0;
                  z-index: 100;
                  width: 100%;
              }

              .section-right-side {
                  background-color: #25262A;
                  min-height: 80vh !important;
                  height: 80% !important;
                  margin-bottom: 14vh !important;
                  //width: 100% !important;
                  z-index: 2 !important;
                  //overflow: hidden;
              }

              .section-container {
                  //overflow-x: hidden !important;
              }

              .section-bottom-img,
              .section-top-img,
              .right-text-all {
                  display: none;
              }

              /*  Верхняя часть */
              .section-left-side-mob {
                  display: block !important;
                  margin: 0 auto;
                  padding: 0;
                  justify-content: center;
                  width: 100%;
                  position: fixed;
                  top: 0;
                  z-index: 2;
                  box-sizing: border-box;
                  border-radius: 5px;
              }

              .right-header3 {
                  display: block !important;
                  color: #26272b;
                  font-size: 2.5vh;
                  margin-left: 10%;
                  margin-right: 10%;
                  text-align: center;
                  background-color: #fba91b;
                  margin-top: 4%;
                  margin-bottom: 4%;

              }

              /*  КНОПКА ПОДРОБНЕЕ */
              .about-button {
                  text-align: center;
                  width: 90px;
                  height: 20px;
                  border: 10px solid #fba91b;
                  background-color: #fba91b;
                  border-radius: 5px;
              }
              .button {
                  position: absolute;
                  right: 20px;
                  bottom: 0;
              }


              /*  КНОПКА ФОТО */
              .button-photo {
                  display: block !important;
                  text-align: center;
                  width: 30px;
                  height: 30px;
                  border-radius: 5px;
                  position: absolute;
                  right: 140px;
                  bottom: 15px;
                  background-color: #fba91b;
                  padding: 5px;
              }
              .button-photo img {
                  width: 20px;
                  height: 100%;

              }


              /*  Нижний Хэдер */
              .footer {
                  display: block !important;
                  position: fixed;
                  bottom: 0;
                  z-index: 100;
                  width: 100%;
              }

              .quote,
              .quoteAutor,
              .coldVideo,
              .windVideo,
              .hotVideo,
              .waterVideo,
              .section-left-side-fullhd,
              .arrow-down {
                  display: none !important;
              }





          }
