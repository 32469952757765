@media screen and (max-width: 833px) {
    header {
        padding: 30px 30px 30px 30px;
    }


    header .header__left__logo {
        width: auto;
        height: 38px;
        margin-top: -10px;
    }

    .menu {
        padding: 3%;
    }

    .menu-container {
        justify-content: flex-end;
    }

    .menu-left-side {
        display: none;
    }

    .menu-right-side {
        width: 100%;
        padding-right: 30px;
        padding-left: 30px;
        padding-top: 70px;
        padding-bottom: 70px;
    }

    .menu-link-text {
        font-size: 16px;
        line-height: 24px;
    }

    .menu-link-text1 {
        font-size: 16px;
        line-height: 24px;
    }

    .menu-link {
        text-align: left;
    }

    .menu-address {
        font-size: 16px;
        line-height: 26px;
        text-align: left;
        margin-top: 10px;

    }

    .menu-address1 {
        font-size: 16px;
        line-height: 26px;
        text-align: left;

    }

    .menu-phone {
        font-size: 16px;
        line-height: 40px;
        text-align: left;
    }

    .menu-mail {
        font-size: 20px;
        line-height: 24px;
        text-align: left;
    }

    .burger-menu {
        height: 20px;
        width: 24px;
    }

    .burger-bar {
        width: 24px;
        height: 2px;
        border-radius: 1px;
    }

    .burger-bar.clicked:nth-child(1),
    .burger-bar.clicked:nth-child(3) {
        width: 27px;
    }
}