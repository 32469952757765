.section-left-side-fullhd{
   display: none !important;
}

.section-right-side{
    background-color: #25262A;
}

.left-header-fullhd {
    font-size: 36px;
    color: #fba91b;
    position: relative;
    top: 100px;
    margin-left: 10%;
    margin-right: 10%;
}

.right-header-company {
    font-size: 36px;
    color: #FBA91B;

}

.text-h2 {
    color: rgba(255, 255, 255, 0.81);
    display: flex;
    font-size: 1.3vw;
    margin-left: 7vw;
    margin-right: 7vw;
    text-decoration: none;
    opacity: 100%;
    animation: text2 1s 1;
}

.right-text {
    color: rgba(255, 255, 255, 0.58);
    position: relative;
    font-size: 28px;
    top: 100px;
    margin-left: 10%;
    margin-right: 100px;
}

.author {
    color: rgba(255, 255, 255, 0.58);
    position: relative;
    font-size: 28px;
    top: 100px;
    margin-right: 10%;

}

 .div-maps {
     display: flex;
     height: 40vh;
 }

.maps {
    margin-left: 7vw;
    margin-right: 7vw;
    width: 82vw;
    height: auto;
    border-radius: 1vw;

}

.content-contacts {
    position: fixed;
}

.fit-picture {
    height: 104vh;
    width: 100%;
    position: fixed;
    top: -4vh;
    margin: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    filter: blur(3px);
    display: flex;
    opacity: 40%;
}
