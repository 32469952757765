@media screen and (max-width: 833px) {
    .element {
        width: 106px;
        height: 106px;
    }

    .element img {
        width: 106px;
        height: 106px;
        border-radius: 10px;
        margin-top: -40px;
    }

    .ready-desicion-text {
        font-size: 20.5px;
        transform: translate(-49%, 350%);
        margin-top: -40px;
    }
}

