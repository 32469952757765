.footer {
    display: none !important;
}

.background-header {
    position: fixed;
    right: 0;
    top: 0;
    width: 4vw;
    height: 100%;
    background: linear-gradient(180deg, #EE423E 0%, #D9D2C4 100%);
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25) inset;
    z-index: 1;
}

.section-right-side {
    background-color: #25262A;
    width: 96%;
}

.right-header3 {
    display: none;
}

.right-text3 {
    color: rgba(255, 255, 255, 0.58);
    position: relative;
    font-size: 28px;
    top: 14%;
    margin-left: 100px;
    margin-right: 100px;
    line-height: 220%;
    justify-content: center;
}


.cards-hot {
    display: none;
}

.card-text77 {
    display: block;
    padding-left: 20px;
    padding-top: 10px;
    color: white;
    font-size: 17px;
}

.section-left-side-fullhd {
    display: block;
    width: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.about-hot-button{
    text-align: center;
    width: 90px;
    height: 20px;
    border: 10px solid #e7423e;
    background-color: #e7423e;
    border-radius: 5px;
    color: rgba(255, 255, 255, 0.82);
}

.text-photo{
    display: flex;
    flex-wrap: wrap;
    background-color: none;
    width: 86%;
    height: auto;
    margin: 0% 7% 7% 7%;
}

.text-photo2{
    display: flex;
    flex-wrap: wrap;
    background-color: none;
    width: 86%;
    height: auto;
    margin: 0% 7% 7% 12%;

}


@keyframes text4 {
    0%{

        opacity: 0;

    }
    40%{
        opacity: 0;


    }
    100%{
        opacity: 100%;


    }
}
.photo-right{
    display: flex;
    position: absolute;
    right: 7%;
    background-color: #fdfdfd;
    width: 43%;
    height: 40%;
    border-radius: 5px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("http://localhost:3000/static/media/heatPageData1.3c3ea3d99b4633e4f32b.jpg");
    animation: photo1 3s 1;

}

@keyframes photo1 {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 100%;
    }

}



.photo-left{
    display: flex;
    position: absolute;
    left: 7%;
    background-color: #fdfdfd;
    width: 43%;
    height: 40%;
    border-radius: 5px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("http://localhost:3000/static/media/heatPageData1.3c3ea3d99b4633e4f32b.jpg");
}

.name-proizvodstvo-carousel {
    display: none;
}