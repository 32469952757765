@media screen and (max-width: 833px) {

    @keyframes fade-in {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    @keyframes fade-out {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
        }
    }

    @keyframes mymove {
        0% {
            top: 100%;

        }
        100% {
            bottom: 0;
        }
    }

    @keyframes mymove2 {
        to {
            margin-bottom: 100%;
        //height: 0%;
        }

        from {
            margin-top: 100%;
            height: 0;
        }
    }

    @keyframes mymove3 {
        0% {
            right: 100%;

        }
        100% {
            left: 0;
        }
    }

    /*  для FULL HD */
    .right-text3 {
        display: none;
    }
    .right-header {
        display: none;
    }
    .section-left-side-fullhd {
        display: none;
    }

    /*  Верхняя часть */
    .section-left-side-mob {
        margin: 0 auto;
        display: block;
        padding: 0;
        justify-content: center;
        width: 100%;
        position: fixed;
        top: 0;
        z-index: 2;
        box-sizing: border-box;
    }
    .right-header3 {
        display: block;
        color: #26272b;
        font-size: 2.5vh;
        margin-left: 10%;
        margin-right: 10%;
        text-align: center;
        background-color: #fba91b;
        margin-top: 4%;
        margin-bottom: 4%;

    }

    /*  Нижняя часть */
    .section-right-side {
        margin-top: 8vh;
        width: 100%;
    }

    .header {
        display: none !important;
    }

    /*  КАРУСЕЛЬ */

        .cards-hot {
            display: block;
            text-align: center;
            margin-top: 10%;
        }

        .name-proizvodstvo-carousel {
            display: block;
            color: white;
            font-size: 18px;
            margin-left: 17px;
            margin-right: 17px;
            padding-right: 17px;
            margin-top: 30px;

        }
        .cards-proizvodstvo {
            display: block;
            margin-top: 5%;
            width: 100%;
            border-radius: 5px;
        }
        .card-proizvodstvo-div1 {
            overflow: hidden;
            margin-inline: 15px;
            width: 80%;
            height: auto;
            border-radius: 5px;
        }
        .card-proizvodstvo-div-perekrestok {
            overflow: hidden;
            margin-inline: 15px;
            width: 80%;
            height: 100%;
            border-radius: 5px;
        }
        .card-proizvodstvo-div-header {
            width: 100%;
            height: 60px;
            text-align: center;
        }
        .card-proizvodstvo-div-header img {
            width: 55%;
            height: 100%;

        }
        .card-text77 {
            display: block;
            text-align: left;
            padding: 20px;
            color: #ffffff;
            font-size: 19px;
            margin-top: 0;
            margin-bottom: 0;
            font-weight: lighter;

        }
        .card-proizvodstvo-img1 {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            width: 100%;
            height: 310px;
            position: relative;
            bottom: 0;
            background-color: #4C4C4F;
        }





}


