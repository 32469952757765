.element {

    position: absolute;
    top: 50%;
    left: 50%;
    cursor: pointer;
}

.element img {
    border-radius: 10px;
    width: 100%;
    height: 100%;
}

.element-title {
    width: 100px;
    height: 14.5%;
    padding: 12px 18px;
    box-sizing: border-box;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    font-family: 'CirceLight';
    font-size: 750%;
    line-height: 144px;
    color: #25262A;
    margin-left: auto;
    cursor: pointer;
    display: flex;
}

.ready-desicion-text {
    padding: 0;
    margin: 0;
    text-align: center;
    font-size: 1.9vw;
    line-height: 33px;
    letter-spacing: 0.05vw;
    color: rgba(255, 255, 255, 0.58);
    position: absolute;
    top: 50%;
    left: 49.7%;
    transform: translate(-49%, 540%);
    white-space: nowrap;
}

.element,
.element-title,
.ready-desicion-text {
    user-select: none;
}

.hot {
    transform: translate(calc(-100% - 5px), calc(-100% - 5px))
}

.water {
    transform: translate(5px, calc(-100% - 5px))
}

.wind {
    transform: translate(calc(-100% - 5px), 5px)
}

.cold {
    transform: translate(5px, 5px)
}

.background-header-yellow {
    position: fixed;
    right: 0;
    top: -16px;
    width: 4vw;
    height: 100%;
    display: flex;
    background: #FBA91B;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25) inset;
}