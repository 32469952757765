        .mobile-menu {
          height: 8vh;
          background-color: #363639;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 4%;
          position: sticky;
          bottom: calc(18px + env(safe-area-inset-bottom));
          z-index: 100;
          text-align: center;

        }

        .mobile-menu-link {
          display: block;
          width: 15%;
          height: auto;
          margin-top: 1.5%;
          padding: 3px;
          border-radius: 15%;
          color: white;
          position: sticky;
          z-index: 100;
          justify-content: center;
          align-items: center;
        }


        .logo-icon {
          display: block;
          width: 3vh;
          height: auto;
          padding: 3px;
          position: sticky;
          //bottom: calc(18px + env(safe-area-inset-bottom));
          z-index: 100;
        }

        .logo-icon-menu img {
          width: 2.7vh;
          height: auto;
          color: white;
          position: sticky;
          bottom: 0;
          z-index: 100;
          justify-content: center;
          align-items: center;
        }

        .mobile-menu-link img {
          width: 6vh;
          height: auto;
          color: white;
          text-align: center;
          justify-content: center;
          align-items: center;
          position: sticky;
          //bottom: calc(18px + env(safe-area-inset-bottom));
          z-index: 100;
        }

        /* модальное окно МЕНЮ */
        .hystmodal-menu {
          position: fixed;
          top: 3%;
          left: 5%;
          -webkit-overflow-scrolling: touch;
          display: flex;
          flex-flow: column nowrap;
          justify-content: center; /* см. ниже */
          align-items: center;
          z-index: 120;
          border-radius: 1vh;
          height: 87%;
          width: 90%;
          background-color: #575757;
          //background-color: rgba(17, 17, 17, 0.56);
          //backdrop-filter: blur(40px);
          animation: mymove2 0.5s;

        }
        .hystmodal-menu__wrap {
          display: block;
          top: 0;
          height: 80%;
          width: 100%;
          border-radius: 20px;
        }
        .hystmodal-menu__window {
          background: none;
          height: 110vh;
        }
        .header-menu__modal {
          background: none;
          mix-blend-mode: normal;
          display: flex;
          position: sticky;
          top: 0;
          width: 100%;
          height: auto;
          z-index: 3;
          border-radius: 20px;
        }
        .all-menu_text_modal {
          color: white;
          margin-top: 7vh;
          margin-left: 20px;
          margin-right: 15px;
          position: relative;
          text-transform: uppercase;
          line-height: 3.5vh;
          font-size: 2.5vh;
          text-align: left;
        }
        .all-menu_text_modal2 {
          position: absolute;
          bottom: 5%;
          color: rgba(255, 255, 255, 0.49);
          margin-left: 20px;
          margin-right: 15px;
          line-height: 3vh;
          font-size: 1.6vh;
        }
        .h1-menu_main_modal {
          font-size: 24px;
          color: #fba91b;
          display: block;
          margin: 16px 0px 16px 15px;
        }
        .hystmodal-menu--active{
          visibility: visible;
        }
        .hystmodal-menu--active .hystmodal__window{
          transform: scale(1);
          opacity: 1;
        }
        .hystmodal-menu__shadow{
          border:none;
          display: block;
          width: 100%;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          overflow: hidden;
          pointer-events: none;
        //z-index: 98;
          opacity: 0;
          transition: opacity 0.15s ease;
          background-color: black;
        }
        .hystmodal-menu__shadow--show{
          pointer-events: auto;
          opacity: 0.6;
        }
        .hystmodal-menu__opened {
          position: fixed;
          right: 0;
          left: 0;
          overflow: hidden;
        }
        .hystmodal-menu__close {
          position: absolute;
          right: 2vh;
          top: 2vh;
          border-radius: 100px;
          width: 40px;
          height: 40px;
          text-align: center;
          background-color: rgba(255, 255, 255, 0.15);
          color: #d60000;
          font-weight: bold;
          padding: 0;
          border: 0;

        }



