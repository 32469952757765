.loading__container {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
}

.svg {
    display: block;
    width: 20%;
    overflow: visible;
    stroke: #FBA91B;
    stroke-width: 0.3px;
    stroke-linejoin: round;
    stroke-linecap: round;
}

@media screen and (max-width: 595px) {
    .svg {
        width: 50%;
    }
}